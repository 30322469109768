@import 'base';


.styletab-container {
  position: fixed; 
  left: -15px;
  transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  -webkit-transform: translateY(-80px);
  transition: all 1000ms;
  z-index: 100;
}

.styletab-container-open {
  position: fixed; 
  left: -15px;
  transform: translateY(0px);
  -ms-transform: translateY(0px);
  -webkit-transform: translateY(0px);
  transition: all 1000ms;
  z-index: 100;
}

.styletab-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 80px;
  border-bottom-right-radius: 5px;

  button {
    border-radius: 10px;
    color: white;
    padding: 8px;
    font-family: $secondary-font;
    font-size: 1.25rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 8px;
    cursor: pointer;
  }
}

.styletab-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 30px;
  border-bottom-right-radius: 5px;
  transform: skewX(-40deg);
  -ms-transform: skewX(-40deg);
  -webkit-transform: skewX(-40deg);

  p {
    margin: 0;
    font-size: 1.75rem;
    text-align: center;
    transform: skewX(40deg);
    -ms-transform: skewX(40deg);
    -webkit-transform: skewX(40deg);
  }
}

.styletab-toggle:hover {
  cursor: pointer;
}