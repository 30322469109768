@import 'base';

header {
  padding: 28px;
  text-align: center;
  min-height: 100vh;
  transition: color 600ms;
  
  .container {
    margin-top: 30vh;
  }
  
  h1 {
    font-family: $header-font;
    font-size: 8.75rem;
    margin: 0;
  }
  
  h3 {
    font-family: $header-font;
    font-size: 3rem;
    font-weight: 100;
    margin: 0;
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.5rem;
    margin-top: 20px;

    a {
      text-decoration: none;
      color: inherit;
    }

    i {
      margin: 0 10px;
      transition: transform 300ms;
    }
    i:hover {
      transform: scale(1.2);
    }
  }

  .arrows {
    margin: 0;
    font-size: 3.5rem;
    position: absolute;
    bottom: 2%;
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;
  }
  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }
  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }
}

@media screen and (max-width: 697px) {
  header {
    h1 {
      font-size: 4.5rem;
    }

    h3 {
      font-size: 1.75rem;
    }
  }
}

@media screen and (max-width: 367px) {
  header {
    .container {
      margin-top: 20vh;
    }

    h1 {
      font-size: 3.75rem;
    }
  }
}

@media screen and (max-width: 825px) and (orientation: landscape) {
  header {
    h1 {
      font-size: 4.5rem;
    }

    h3 {
      font-size: 1.75rem;
    }
  }
}

@media screen and (max-width: 825px) and (orientation: landscape) {
 .arrows {
   right: 5%;
 }
}