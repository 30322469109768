@import 'base';

h1 {
  margin-left: -8%;
  font-size: 3rem;
}

a {
  text-decoration: none;
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
}

.project-box {
  width: 360px;
  height: 180px;
  border-radius: 5px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  .text-default {
    opacity: 0;
    transition: opacity 600ms;
    font-family: $header-font;
    font-size: 5rem;
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .text-hover {
    opacity: 0;
    transition: opacity 600ms;
    font-size: 1.75rem;
    width: 100%;
    text-align: center;
  }
}

.project-box:hover {
  background-size: 110%;

  .text-default {
    opacity: 1;
  }
  .text-hover {
    opacity: 1;
  }
}

.stable {
  background: url('../assets/ss2.png') no-repeat;
  background-size: 100%;
  background-position: center;
  transition: background-size 600ms;
}

.chatty {
  background: url('../assets/chatty_ss.png') no-repeat;
  background-size: 100%;
  background-position: center;
  transition: background-size 600ms;
}

.eftfg {
  background: url('../assets/eftfg_ss.png') no-repeat;
  background-size: 100%;
  background-position: center;
  transition: background-size 600ms;
}

.tweeter {
  background: url('../assets/tweeter_ss.png');
  background-size: 100%;
  background-position: center;
  transition: background-size 600ms;
}

.yegbot {
  background: url('../assets/yegbot_ss.png');
  background-size: 100%;
  background-position: center;
  transition: background-size 600ms;
}

.tinyapp {
  background: url('../assets/tinyapp_ss.png');
  background-size: 100%;
  background-position: center;
  transition: background-size 600ms;
}

@media screen and (max-width: 697px) {
  .project-box {
    width: 240px;
    height: 120px;

    .text-default {
      font-size: 3.5rem;
      opacity: 1;
    }
    .text-hover {
      font-size: 1.5rem;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 825px) and (orientation: landscape) {
  .project-box {
    .text-default {
      font-size: 4rem;
      opacity: 1;
    }
    .text-hover {
      opacity: 1;
    }
  }
}