@import 'base';

.about {
  min-height: 100vh;

  h1 {
    margin-left: -8%;
    font-size: 3rem;
  }

  p {
    font-size: 2rem;
    line-height: 3.5rem;
  }

  .bold {
    font-weight: 600;
  }
}

@media screen and (max-width: 600px) {
  .about {
    margin-bottom: 150px !important;

    p {
      font-size: 1.5rem;
    }
  }
}