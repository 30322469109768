@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600|Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600|Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600|Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600|Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600|Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600|Permanent+Marker);
body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Fonts */
/* Variables */
* {
  box-sizing: border-box; }

html, body {
  color: #FAFAFA;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin: 0;
  padding: 0; }

.main-container {
  position: absolute;
  z-index: 999; }

.container {
  margin: 0 auto;
  max-width: 70%;
  padding: 0 24px; }

/* Fonts */
/* Variables */
* {
  box-sizing: border-box; }

html, body {
  color: #FAFAFA;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin: 0;
  padding: 0; }

.main-container {
  position: absolute;
  z-index: 999; }

.container {
  margin: 0 auto;
  max-width: 70%;
  padding: 0 24px; }

.particles {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: -10; }

/* Fonts */
/* Variables */
* {
  box-sizing: border-box; }

html, body {
  color: #FAFAFA;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin: 0;
  padding: 0; }

.main-container {
  position: absolute;
  z-index: 999; }

.container {
  margin: 0 auto;
  max-width: 70%;
  padding: 0 24px; }

.styletab-container {
  position: fixed;
  left: -15px;
  transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  -webkit-transform: translateY(-80px);
  -webkit-transition: all 1000ms;
  transition: all 1000ms;
  z-index: 100; }

.styletab-container-open {
  position: fixed;
  left: -15px;
  transform: translateY(0px);
  -ms-transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -webkit-transition: all 1000ms;
  transition: all 1000ms;
  z-index: 100; }

.styletab-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 80px;
  border-bottom-right-radius: 5px; }
  .styletab-info button {
    border-radius: 10px;
    color: white;
    padding: 8px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.25rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 8px;
    cursor: pointer; }

.styletab-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 30px;
  border-bottom-right-radius: 5px;
  transform: skewX(-40deg);
  -ms-transform: skewX(-40deg);
  -webkit-transform: skewX(-40deg); }
  .styletab-toggle p {
    margin: 0;
    font-size: 1.75rem;
    text-align: center;
    transform: skewX(40deg);
    -ms-transform: skewX(40deg);
    -webkit-transform: skewX(40deg); }

.styletab-toggle:hover {
  cursor: pointer; }

/* Fonts */
/* Variables */
* {
  box-sizing: border-box; }

html, body {
  color: #FAFAFA;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin: 0;
  padding: 0; }

.main-container {
  position: absolute;
  z-index: 999; }

.container {
  margin: 0 auto;
  max-width: 70%;
  padding: 0 24px; }

header {
  padding: 28px;
  text-align: center;
  min-height: 100vh;
  -webkit-transition: color 600ms;
  transition: color 600ms; }
  header .container {
    margin-top: 30vh; }
  header h1 {
    font-family: "Permanent Marker", sans-serif;
    font-size: 8.75rem;
    margin: 0; }
  header h3 {
    font-family: "Permanent Marker", sans-serif;
    font-size: 3rem;
    font-weight: 100;
    margin: 0; }
  header .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.5rem;
    margin-top: 20px; }
    header .icons a {
      text-decoration: none;
      color: inherit; }
    header .icons i {
      margin: 0 10px;
      -webkit-transition: -webkit-transform 300ms;
      transition: -webkit-transform 300ms;
      transition: transform 300ms;
      transition: transform 300ms, -webkit-transform 300ms; }
    header .icons i:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
  header .arrows {
    margin: 0;
    font-size: 3.5rem;
    position: absolute;
    bottom: 2%;
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate; }

@keyframes bounce {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); } }

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  to {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px); } }

@media screen and (max-width: 697px) {
  header h1 {
    font-size: 4.5rem; }
  header h3 {
    font-size: 1.75rem; } }

@media screen and (max-width: 367px) {
  header .container {
    margin-top: 20vh; }
  header h1 {
    font-size: 3.75rem; } }

@media screen and (max-width: 825px) and (orientation: landscape) {
  header h1 {
    font-size: 4.5rem; }
  header h3 {
    font-size: 1.75rem; } }

@media screen and (max-width: 825px) and (orientation: landscape) {
  .arrows {
    right: 5%; } }

/* Fonts */
/* Variables */
* {
  box-sizing: border-box; }

html, body {
  color: #FAFAFA;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin: 0;
  padding: 0; }

.main-container {
  position: absolute;
  z-index: 999; }

.container {
  margin: 0 auto;
  max-width: 70%;
  padding: 0 24px; }

.about {
  min-height: 100vh; }
  .about h1 {
    margin-left: -8%;
    font-size: 3rem; }
  .about p {
    font-size: 2rem;
    line-height: 3.5rem; }
  .about .bold {
    font-weight: 600; }

@media screen and (max-width: 600px) {
  .about {
    margin-bottom: 150px !important; }
    .about p {
      font-size: 1.5rem; } }

/* Fonts */
/* Variables */
* {
  box-sizing: border-box; }

html, body {
  color: #FAFAFA;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 100;
  margin: 0;
  padding: 0; }

.main-container {
  position: absolute;
  z-index: 999; }

.container {
  margin: 0 auto;
  max-width: 70%;
  padding: 0 24px; }

h1 {
  margin-left: -8%;
  font-size: 3rem; }

a {
  text-decoration: none; }

.project-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%; }

.project-box {
  width: 360px;
  height: 180px;
  border-radius: 5px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
  .project-box .text-default {
    opacity: 0;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    font-family: "Permanent Marker", sans-serif;
    font-size: 5rem;
    width: 100%;
    text-align: center;
    margin: 0; }
  .project-box .text-hover {
    opacity: 0;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    font-size: 1.75rem;
    width: 100%;
    text-align: center; }

.project-box:hover {
  background-size: 110%; }
  .project-box:hover .text-default {
    opacity: 1; }
  .project-box:hover .text-hover {
    opacity: 1; }

.stable {
  background: url(/static/media/ss2.da308632.png) no-repeat;
  background-size: 100%;
  background-position: center;
  -webkit-transition: background-size 600ms;
  transition: background-size 600ms; }

.chatty {
  background: url(/static/media/chatty_ss.1c4b467f.png) no-repeat;
  background-size: 100%;
  background-position: center;
  -webkit-transition: background-size 600ms;
  transition: background-size 600ms; }

.eftfg {
  background: url(/static/media/eftfg_ss.ff930de9.png) no-repeat;
  background-size: 100%;
  background-position: center;
  -webkit-transition: background-size 600ms;
  transition: background-size 600ms; }

.tweeter {
  background: url(/static/media/tweeter_ss.e9f31c4a.png);
  background-size: 100%;
  background-position: center;
  -webkit-transition: background-size 600ms;
  transition: background-size 600ms; }

.yegbot {
  background: url(/static/media/yegbot_ss.ed919052.png);
  background-size: 100%;
  background-position: center;
  -webkit-transition: background-size 600ms;
  transition: background-size 600ms; }

.tinyapp {
  background: url(/static/media/tinyapp_ss.d7422d02.png);
  background-size: 100%;
  background-position: center;
  -webkit-transition: background-size 600ms;
  transition: background-size 600ms; }

@media screen and (max-width: 697px) {
  .project-box {
    width: 240px;
    height: 120px; }
    .project-box .text-default {
      font-size: 3.5rem;
      opacity: 1; }
    .project-box .text-hover {
      font-size: 1.5rem;
      opacity: 1; } }

@media screen and (max-width: 825px) and (orientation: landscape) {
  .project-box .text-default {
    font-size: 4rem;
    opacity: 1; }
  .project-box .text-hover {
    opacity: 1; } }

